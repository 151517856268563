import React, { FC } from 'react';
import classNames from 'classnames';

import NavItem from 'components/molecules/NavItem';
import Logo from 'components/atoms/Logo';

import { useScreenService } from 'hooks/useScreenService';

import { NavMenuProps } from './models.d';

const NavMenu: FC<NavMenuProps> = ({ navItems, opened, position = 'left', setMenuOpened }) => {
  const { isMd, isSm } = useScreenService();

  const menuClassnames = classNames('nav-menu', {
    'nav-menu--opened': (isMd || isSm) && opened,
    [`nav-menu--${position}`]: position,
  });

  return (
    <nav className={menuClassnames}>
      {navItems.slice(0, navItems.length / 2).map((item) => (
        <NavItem key={item.pageName} navItem={item} setMenuOpened={setMenuOpened} />
      ))}
      {!isMd && !isSm ? (
        <Logo key="logo" setMenuOpened={setMenuOpened} customClass="logo--desktop" />
      ) : null}
      {navItems.slice(navItems.length / 2).map((item) => (
        <NavItem key={item.pageName} navItem={item} setMenuOpened={setMenuOpened} />
      ))}
    </nav>
  );
};

export default NavMenu;
