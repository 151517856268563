import React from 'react';

import { ICONS_SVG } from 'components/atoms/IconsSVG';
import ArrowDown from 'components/atoms/IconsSVG/ArrowDown';
import ArrowLeft from 'components/atoms/IconsSVG/ArrowLeft';
import ArrowRight from 'components/atoms/IconsSVG/ArrowRight';
import ArrowUp from 'components/atoms/IconsSVG/ArrowUp';
import CrossIcon from 'components/atoms/IconsSVG/CrossIcon';
import EmailIcon from 'components/atoms/IconsSVG/EmailIcon';
import FacebookIcon from 'components/atoms/IconsSVG/FacebookIcon';
import FacebookIconFilled from 'components/atoms/IconsSVG/FacebookIconFilled';
import InstagramIcon from 'components/atoms/IconsSVG/InstagramIcon';
import InstagramIconFilled from 'components/atoms/IconsSVG/InstagramIconFilled';
import MarkerIcon from 'components/atoms/IconsSVG/MarkerIcon';
import MinusIcon from 'components/atoms/IconsSVG/MinusIcon';
import PhoneIcon from 'components/atoms/IconsSVG/PhoneIcon';
import PlusIcon from 'components/atoms/IconsSVG/PlusIcon';

export const getIconSvg = (variant: CommonTypes.IconVariantType | string, className?: string) => {
  if (variant === 'none') return null;

  const iconsMap = {
    [ICONS_SVG.VARIANT.FACEBOOK]: <FacebookIcon className={className} />,
    [ICONS_SVG.VARIANT.FACEBOOK_FILLED]: <FacebookIconFilled className={className} />,
    [ICONS_SVG.VARIANT.INSTAGRAM]: <InstagramIcon className={className} />,
    [ICONS_SVG.VARIANT.INSTAGRAM_FILLED]: <InstagramIconFilled className={className} />,
    [ICONS_SVG.VARIANT.ARROW_DOWN]: <ArrowDown className={className} />,
    [ICONS_SVG.VARIANT.ARROW_UP]: <ArrowUp className={className} />,
    [ICONS_SVG.VARIANT.ARROW_LEFT]: <ArrowLeft className={className} />,
    [ICONS_SVG.VARIANT.ARROW_RIGHT]: <ArrowRight className={className} />,
    [ICONS_SVG.VARIANT.PHONE]: <PhoneIcon className={className} />,
    [ICONS_SVG.VARIANT.EMAIL]: <EmailIcon className={className} />,
    [ICONS_SVG.VARIANT.CROSS]: <CrossIcon className={className} />,
    [ICONS_SVG.VARIANT.PLUS]: <PlusIcon className={className} />,
    [ICONS_SVG.VARIANT.MINUS]: <MinusIcon className={className} />,
    [ICONS_SVG.VARIANT.MARKER]: <MarkerIcon className={className} />,
  };

  return iconsMap[variant];
};
