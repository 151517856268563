import React, { FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { LinkProps } from './models.d';

const Link: FC<LinkProps> = ({ to, label, customClass, setMenuOpened, children, state, aria }) => {
  const isExternal = to.startsWith('http') || to.startsWith('https');

  return isExternal ? (
    <a href={to} className={customClass} aria-label={aria}>
      {children || null}
      {label}
    </a>
  ) : (
    <GatsbyLink
      className={customClass}
      to={to}
      activeClassName={`${customClass}--active`}
      onClick={() => setMenuOpened && setMenuOpened(false)}
      state={state}
      aria-label={aria}
    >
      {children || null}
      {label}
    </GatsbyLink>
  );
};
export default Link;
