import React from 'react';

const MarkerIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    width="200px"
    height="200px"
    viewBox="796 796 200 200"
    enableBackground="new 796 796 200 200"
    xmlSpace="preserve"
    fill="currentcolor"
    className={className}
  >
    <path d="M970.135,870.134C970.135,829.191,936.943,796,896,796c-40.944,0-74.135,33.191-74.135,74.134  c0,16.217,5.221,31.206,14.055,43.41l-0.019,0.003L896,996l60.099-82.453l-0.019-0.003  C964.912,901.34,970.135,886.351,970.135,870.134z M896,900.006c-16.497,0-29.871-13.374-29.871-29.872s13.374-29.871,29.871-29.871  s29.871,13.373,29.871,29.871S912.497,900.006,896,900.006z" />
  </svg>
);

export default MarkerIcon;
