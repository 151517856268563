import React, { FC } from 'react';

import Link from 'components/atoms/Link';

import { NavItemProps } from './models.d';

const NavItem: FC<NavItemProps> = ({ navItem, setMenuOpened }) => {
  const { pageName, slug } = navItem;
  return (
    <Link
      to={slug}
      label={pageName}
      customClass="nav-item"
      setMenuOpened={setMenuOpened}
      aria={pageName}
    />
  );
};

export default NavItem;
