import React from 'react';

const FacebookIconFilled = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="currentColor"
    className={className}
  >
    <path
      d="M0 0V40H40V0H0ZM32.25 21H27V35H21.75V21H18V16H21.75V11.75C21.75 8 24 5 29 5C31 5 32.5 5.25 32.5 5.25V10H29.25C27.5 10 27.25 10.75 27.25 12.25V16H32.5L32.25 21Z"
      fill="currentColor"
    />
  </svg>
);

export default FacebookIconFilled;
