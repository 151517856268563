import React from 'react';

const FacebookIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    viewBox="0 0 28 28"
    fill="currentColor"
    className={className}
  >
    <path
      d="M2.75 0.25C1.3825 0.25 0.25 1.3825 0.25 2.75V25.25C0.25 26.6175 1.3825 27.75 2.75 27.75H25.25C26.6175 27.75 27.75 26.6175 27.75 25.25V2.75C27.75 1.3825 26.6175 0.25 25.25 0.25H2.75ZM2.75 2.75H25.25V25.25H18.765V16.8125H22.0075L22.4763 13.0625H18.7662V10.64C18.7662 9.5475 19.0338 8.805 20.6013 8.805H22.6325V5.40625C22.2863 5.3625 21.085 5.28875 19.7037 5.28875C16.8225 5.28875 14.86 7.0225 14.86 10.25V13.0625H11.5787V16.8125H14.8588V25.25H2.75V2.75Z"
      fill="currentColor"
    />
  </svg>
);

export default FacebookIcon;
