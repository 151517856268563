import React, { FC } from 'react';
import classNames from 'classnames';

import { HamburgerProps } from './models.d';

const Hamburger: FC<HamburgerProps> = ({ menuOpened, setMenuOpened }) => {
  const hamburgerClassnames = classNames('hamburger', { 'hamburger--opened': menuOpened });

  return (
    <button
      className={hamburgerClassnames}
      type="button"
      onClick={() => setMenuOpened(!menuOpened)}
      aria-label="hamburger"
    >
      <span className="hamburger__bar" />
      <span className="hamburger__bar" />
      <span className="hamburger__bar" />
    </button>
  );
};

export default Hamburger;
