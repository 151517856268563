import React, { FC, useState } from 'react';
import { motion, Variants } from 'framer-motion';

import NavMenu from 'components/molecules/NavMenu';
import Hamburger from 'components/atoms/Hamburger';
import Logo from 'components/atoms/Logo';

import { HeaderProps } from './models.d';

const Header: FC<HeaderProps> = ({ menuItems }) => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  const headerVariants: Variants = {
    hidden: {
      opacity: 0,
      y: -100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
      },
    },
  };

  return (
    <motion.header className="header" variants={headerVariants} initial="hidden" animate="visible">
      <Logo setMenuOpened={setMenuOpened} customClass="logo--mobile" />
      <NavMenu
        navItems={menuItems.slice(1)}
        opened={menuOpened}
        position="center"
        setMenuOpened={setMenuOpened}
      />
      <Hamburger menuOpened={menuOpened} setMenuOpened={setMenuOpened} />
    </motion.header>
  );
};

export default Header;
