exports.components = {
  "component---src-components-templates-trainer-page-trainer-page-tsx": () => import("./../../../src/components/templates/TrainerPage/TrainerPage.tsx" /* webpackChunkName: "component---src-components-templates-trainer-page-trainer-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kadra-tsx": () => import("./../../../src/pages/kadra.tsx" /* webpackChunkName: "component---src-pages-kadra-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-lokalizacje-tsx": () => import("./../../../src/pages/lokalizacje.tsx" /* webpackChunkName: "component---src-pages-lokalizacje-tsx" */),
  "component---src-pages-onas-tsx": () => import("./../../../src/pages/onas.tsx" /* webpackChunkName: "component---src-pages-onas-tsx" */),
  "component---src-pages-program-tsx": () => import("./../../../src/pages/program.tsx" /* webpackChunkName: "component---src-pages-program-tsx" */),
  "component---src-pages-wspolpraca-tsx": () => import("./../../../src/pages/wspolpraca.tsx" /* webpackChunkName: "component---src-pages-wspolpraca-tsx" */),
  "component---src-pages-zajecia-tsx": () => import("./../../../src/pages/zajecia.tsx" /* webpackChunkName: "component---src-pages-zajecia-tsx" */)
}

