export namespace ICONS_SVG {
  export enum VARIANT {
    FACEBOOK = 'facebook',
    FACEBOOK_FILLED = 'facebook-filled',
    INSTAGRAM = 'instagram',
    INSTAGRAM_FILLED = 'instagram-filled',
    ARROW_DOWN = 'arrow-down',
    ARROW_UP = 'arrow-up',
    ARROW_LEFT = 'arrow-left',
    ARROW_RIGHT = 'arrow-right',
    PHONE = 'phone',
    EMAIL = 'email',
    CROSS = 'cross',
    PLUS = 'plus',
    MINUS = 'minus',
    MARKER = 'marker',
  }
}
