import React, { ReactNode } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Footer from 'components/organisms/Footer';
import Header from 'components/organisms/Header';

import '../styles/styles.scss';

const Layout = ({ children }: { children: ReactNode }) => {
  const {
    contentfulHeader: { navigation },
    contentfulSocialMedia: { socialMedia },
    contentfulFooter,
    contentfulContactUs,
    contentfulHomePage: {
      backgroundImage: { gatsbyImageData },
    },
  } = useStaticQuery(graphql`
    {
      contentfulHomePage {
        backgroundImage {
          gatsbyImageData
        }
      }
      contentfulHeader {
        navigation {
          ... on ContentfulHomePage {
            id
            pageName
            slug
          }
          ... on ContentfulAboutUs {
            id
            pageName
            slug
          }
          ... on ContentfulGallery {
            id
            pageName
            slug
          }
          ... on ContentfulOurProgram {
            id
            pageName
            slug
          }
          ... on ContentfulActivities {
            id
            pageName
            slug
          }
          ... on ContentfulPersonnel {
            id
            pageName
            slug
          }
          ... on ContentfulLocations {
            id
            pageName
            slug
          }
          ... on ContentfulPartnership {
            id
            pageName
            slug
          }
          ... on ContentfulContactUs {
            id
            pageName
            slug
          }
        }
      }
      contentfulSocialMedia {
        socialMedia {
          socialName
          socialLink
        }
      }
      contentfulFooter {
        sitemapLabel
        followLabel
      }
      contentfulContactUs {
        companyName
        contactEmail
        contactPhoneNumber
      }
    }
  `);

  return (
    <div className="layout">
      <Header menuItems={navigation} />

      {children}
      <Footer
        navigationLabels={contentfulFooter}
        menuItems={navigation}
        socialLinks={socialMedia}
        contact={contentfulContactUs}
      />
      <GatsbyImage image={gatsbyImageData} alt="background" className="common-background" />
    </div>
  );
};
export default Layout;
