import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion, Variants } from 'framer-motion';

import { LogoProps } from './models.d';

const Logo: FC<LogoProps> = ({ setMenuOpened, customClass }) => {
  const {
    contentfulHeader: {
      logo: {
        image: { gatsbyImageData },
      },
    },
  } = useStaticQuery(graphql`
    {
      contentfulHeader {
        logo {
          image {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const logoVariants: Variants = {
    initial: {
      scale: 1,
    },
    final: {
      scale: 1.1,
      transition: {
        duration: 0.4,
      },
    },
  };

  return (
    <motion.div variants={logoVariants} initial="initial" whileHover="final">
      <Link to="/" onClick={() => setMenuOpened && setMenuOpened(false)} className={customClass}>
        <GatsbyImage image={gatsbyImageData} alt="logo" className="logo" />
      </Link>
    </motion.div>
  );
};

export default Logo;
