import React, { FC } from 'react';

import { ICONS_SVG } from 'components/atoms/IconsSVG';
import Link from 'components/atoms/Link';

import { getIconSvg } from 'utils/getIconSvg';

import { FooterProps } from './models.d';

const Footer: FC<FooterProps> = ({ menuItems, navigationLabels, socialLinks, contact }) => {
  const { companyName, contactEmail, contactPhoneNumber } = contact;
  const { followLabel, sitemapLabel } = navigationLabels;

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer-navigation">
          <p className="footer-navigation__label">{sitemapLabel}</p>
          <ul className="footer-navigation__links">
            {menuItems?.map((item) => (
              <li key={item.pageName} className="footer-navigation__item">
                <Link aria={`link to ${item.pageName}`} label={item.pageName} to={item.slug} />
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-navigation">
          <p className="footer-navigation__label">{followLabel}</p>
          <ul className="footer-navigation__social-links">
            {socialLinks?.map((item) => (
              <li key={item.socialName} className="footer-navigation__social-item">
                <Link aria={`link to ${item.socialName}`} to={item.socialLink}>
                  {getIconSvg(item.socialName.toLowerCase())}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer__contact-wrapper">
          <p className="footer__company-name">{companyName}</p>
          <a href={`tel:${contactPhoneNumber}`} className="footer__contact">
            {getIconSvg(ICONS_SVG.VARIANT.PHONE)}
            <span className="footer__phone-number">{contactPhoneNumber}</span>
          </a>
          <a href={`mailto: ${contactEmail}`} className="footer__contact">
            {getIconSvg(ICONS_SVG.VARIANT.EMAIL)}
            <span className="footer__phone-number">{contactEmail}</span>
          </a>
        </div>
      </div>
      <p className="footer__copyrights">© Copyright {new Date().getFullYear()}</p>
    </footer>
  );
};

export default Footer;
